import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function Spinner({ size, className }) {
  return <div className={`Spinner Spinner_${size} ${className}`} />;
}

Spinner.defaultProps = {
  size: 'default',
  className: '',
};

Spinner.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['default', 'large', 'medium', 'small', 'very_small']),
};

export default Spinner;
