import * as Types from './types';

const initState = {
  game: {
    error: null,
    payload: null,
  },
  countries: {
    error: null,
    payload: null,
  },
  isStopSale: false,
  alertBlocks: {
    isLoading: true,
    error: null,
    payload: null,
  },
};

export default function(state = initState, { type, payload }) {
  switch (type) {
    case Types.GAME_SUCCESS: {
      return { ...state, game: { error: null, payload } };
    }
    case Types.GAME_ERROR: {
      return { ...state, game: { error: payload, payload: null } };
    }
    case Types.COUNTRY_SUCCESS: {
      return { ...state, countries: { payload, error: null } };
    }
    case Types.COUNTRY_ERROR: {
      return { ...state, countries: { error: payload, payload: null } };
    }
    case Types.ALERT_BLOCK_SUCCESS: {
      return {
        ...state,
        alertBlocks: { payload, error: null, isLoading: false },
      };
    }
    case Types.ALERT_BLOCK_ERROR: {
      return {
        ...state,
        alertBlocks: { error: payload, payload: null, isLoading: false },
      };
    }
    case Types.STOP_SALE_CHANGE: {
      return { ...state, isStopSale: payload };
    }
    default: {
      return state;
    }
  }
}
