import ApiService from './ApiService';

const TEST_GIFTCODE_FULL = {
  game: {
    thumbnail:
      'https://www.guinnessworldrecords.com/Images/GTA-V-main_tcm25-19095.jpg',
    name: 'GTA 5',
    region: 'EUROPE',
    description: 'Awesome description',
    releaseDate: '',
  },
  country: {
    name: 'Russia',
    code: 'RU',
  },
  userAgreed: true,
  user: {
    id: '123456',
    name: 'charlibelle86',
    avatar:
      'https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/b5/b5bd56c1aa4644a474a2e4972be27ef9e82e517e_full.jpg',
  },
  bot: {
    name: 'awesome_bot',
    avatar:
      'https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/b5/b5bd56c1aa4644a474a2e4972be27ef9e82e517e_full.jpg',
  },
  friendRequest: {
    status: 'Done',
    updatedAt: new Date().getTime(),
  },
  transferStatus: 'done',
  bonusCode: 'bonusCode',
  // error: { //optional
  //   code: 0 //TODO define values
  //   title: ”Short error description”
  //   type: 0 - warning (user is able to retry) 1- error (activation failed)
  //   description: 'error description”
  //   details:JsonObject //optional
  // }
};

const TEST_GIFTCODE_ONE = {
  game: {
    thumbnail:
      'https://www.guinnessworldrecords.com/Images/GTA-V-main_tcm25-19095.jpg',
    name: 'GTA 5',
    region: 'EUROPE',
    description: 'Awesome description',
    releaseDate: '',
  },
};

const TEST_GIFTCODE_TWO = {
  ...TEST_GIFTCODE_ONE,
  user: {
    id: '123456',
    name: 'charlibelle86',
    avatar:
      'https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/b5/b5bd56c1aa4644a474a2e4972be27ef9e82e517e_full.jpg',
  },
};

const TEST_GIFTCODE_THREE = {
  ...TEST_GIFTCODE_TWO,
  country: {
    name: 'Russia',
    code: 'RU',
  },
  userAgreed: true,
  bot: {
    name: 'awesome_bot',
    avatar:
      'https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/b5/b5bd56c1aa4644a474a2e4972be27ef9e82e517e_full.jpg',
  },
};

const TEST_GIFTCODE_FOUR = {
  ...TEST_GIFTCODE_THREE,
  friendRequest: {
    status: 'DONE',
    updatedAt: new Date().getTime(),
  },
};

function upperCaseFriendRequestAndTransferStatus(payload) {
  if (payload && payload.friendRequest && payload.friendRequest.status) {
    payload.friendRequest.status = payload.friendRequest.status.toUpperCase();
  }

  if (payload && payload.transferStatus) {
    payload.transferStatus = payload.transferStatus.toUpperCase();
  }

  return payload;
}

export default {
  async fetchMain(giftcode, _market, data = null) {
    const debug = { is: false, data: {}, timeout: 3000 };

    switch (giftcode) {
      case 'TEST_GIFTCODE_FULL':
        debug.is = true;
        debug.data = TEST_GIFTCODE_FULL;
        break;

      case 'TEST_GIFTCODE_EMPTY':
        debug.is = true;
        debug.data = {};
        break;

      case 'TEST_GIFTCODE_ONE':
        debug.is = true;
        debug.data = TEST_GIFTCODE_ONE;
        break;

      case 'TEST_GIFTCODE_TWO':
        debug.is = true;
        debug.data = TEST_GIFTCODE_TWO;
        break;

      case 'TEST_GIFTCODE_THREE':
        debug.is = true;
        debug.data = TEST_GIFTCODE_THREE;
        break;

      case 'TEST_GIFTCODE_FOUR':
        debug.is = true;
        debug.data = TEST_GIFTCODE_FOUR;
        break;
      default:
        break;
    }

    if (debug.is) {
      return new Promise((resolve) => {
        setTimeout(
          resolve,
          debug.timeout,
          upperCaseFriendRequestAndTransferStatus(debug.data)
        );
      });
    }

    return ApiService.put('/activation/' + giftcode, data).then(
      upperCaseFriendRequestAndTransferStatus
    );
  },
  sendUrl(giftcode, url) {
    return ApiService.post(`/activation/${giftcode}/account`, { url });
  },
  setCountry(giftcode, code) {
    return ApiService.post(`/activation/${giftcode}/country`, {
      code,
      name: 'Name',
    }).then(() => ({ code }));
  },
  changeAccount(giftcode) {
    return ApiService.delete(`/activation/${giftcode}/account`);
  },
  getFriendRequest(giftcode) {
    return ApiService.get(`/activation/${giftcode}`)
      .then(upperCaseFriendRequestAndTransferStatus)
      .then(({ bot, friendRequest, error, phase }) => ({
        bot,
        friendRequest,
        error,
        phase,
      }));
  },
  setUserAgree(giftcode) {
    return ApiService.post(`/activation/${giftcode}/user_agreed`);
  },
  getGame(giftcode) {
    return ApiService.get(`/activation/${giftcode}`)
      .then(upperCaseFriendRequestAndTransferStatus)
      .then(({ transferStatus, bonusCode, error, phase }) => ({
        transferStatus,
        bonusCode,
        error,
        phase,
      }));
  },
};
