import React from 'react';
import PropTypes from 'prop-types';
import b_ from 'b_';
import { Trans, useTranslation } from 'react-i18next';
import './styles.scss';
import Title from '../../../Components/Title';
import Card from '../../../Components/Card';
import Button from '../../../Components/Button';
import Paragraph from '../../../Components/Paragraph';
import StepButton from '../../../Components/StepButton';
import cx from '../../../Utils/cx';
import { copyToClipboard } from '../../../Utils/clipboard';
import useBoolean from '../../../Utils/useBoolean';
import { getTitle } from 'Utils/envDependent';

const b = b_.lock('ExtraStep');

function ExtraStep({ className = '', bonusCode }) {
  const { t } = useTranslation();

  const {
    value: isViewOk,
    setFalse: hideViewOk,
    setTrue: showViewOk,
  } = useBoolean(false);

  function handleClick() {
    if (!isViewOk) {
      copyToClipboard(bonusCode);
      showViewOk();
      setTimeout(() => {
        hideViewOk();
      }, 3000);
    }
  }

  const title = getTitle();

  return (
    <div className={cx(b(), className)}>
      <StepButton completeMessage="" isComplete={false} withLine={false}>
        5
      </StepButton>
      <Title className={b('title')} level={2}>
        {t('main.steps.five.title')}
      </Title>
      <Card className={b('card')}>
        <div className={b('cardContent', { title })}>
          <Paragraph className={b('text')}>
            <Trans
              i18nKey="main.steps.five.description.0"
              components={[
                <Button className={b('here')} type="link">
                  _
                </Button>,
              ]}
            />
            <br />
            {t('main.steps.five.description.1')}
          </Paragraph>
          <Paragraph className={b('text')}>
            {t('main.steps.five.description.2')}
            <b className={b('code')}>{bonusCode}</b>
          </Paragraph>
          <Paragraph>
            <Trans
              i18nKey="main.steps.five.description.3"
              components={[<span className="bold">_</span>]}
            />
          </Paragraph>
        </div>
      </Card>
      <Button onClick={handleClick} type="primary">
        {isViewOk ? (
          <>
            <div className={b('ok')} />
            {t('controls.buttons.code_copied')}
          </>
        ) : (
          t('controls.buttons.code_copy')
        )}
      </Button>
    </div>
  );
}

ExtraStep.propTypes = {
  className: PropTypes.string,
  bonusCode: PropTypes.string.isRequired,
};

export default ExtraStep;
