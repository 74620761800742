import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import b_ from 'b_';
import Button from '../Button';
import { changeAccount } from '../../Reducers/main/actions';
import useBoolean from '../../Utils/useBoolean';
import Spinner from '../Spinner';

import './styles.scss';

const b = b_.lock('ChangeAccount');

function ChangeAccount({ className }) {
  const dispatch = useDispatch();
  const {
    value: loading,
    setTrue: setLoading,
    setValue: afterLoad,
  } = useBoolean(false);

  const handleClick = useCallback(
    function() {
      setLoading();
      dispatch(changeAccount())
        .then(afterLoad)
        .catch(afterLoad);
    },
    [dispatch]
  );

  const { t } = useTranslation();

  return (
    <Button className={className} onClick={handleClick}>
      {loading && <Spinner className={b('spinner')} size="very_small" />}
      {t('controls.buttons.change_account')}
    </Button>
  );
}

ChangeAccount.defaultProps = {
  className: '',
};

ChangeAccount.propTypes = {
  className: PropTypes.string,
};

export default React.memo(ChangeAccount);
