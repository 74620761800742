import React, { useMemo } from 'react';
import './styles.scss';
import b_ from 'b_';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useTitle from '../../Utils/useTitle';
import Button from '../../Components/Button';
import Title from '../../Components/Title';

const b = b_.lock('Page404');

function Page404() {
  const { t } = useTranslation();

  const title = useMemo(() => t('page_title.not_found'), [t]);

  useTitle(title);

  return (
    <div className="Page404">
      <div className={b('lock')} />
      <div className={b('errorCode')}>404</div>
      <Title level={1} className={b('title')}>
        {t('404.there_nothing_here')}
      </Title>
      <Link to="/faq">
        <Button>{t('404.you_can_read_faq')}</Button>
      </Link>
    </div>
  );
}

export default React.memo(Page404);
