import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import b_ from 'b_';
import './styles.scss';
import { getTitle } from 'Utils/envDependent';

const b = b_.lock('StepButton');

function StepButton({
  isComplete,
  children,
  completeMessage,
  withLine,
  dashed,
  icon,
}) {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      window.scrollTo({
        top: ref.current.getBoundingClientRect().top + window.pageYOffset,
        behavior: 'smooth',
      });
    }
  }, [ref]);

  const title = getTitle();

  return (
    <>
      <div
        className={b(`line`, {
          [""]: isComplete ? `${title}_isComplete` : `${title}`,
          ["_"]: dashed ? `${title}_dashed` : `${title}`,
          hide: !withLine,
        })}
        ref={ref}
      />
      <div className={b({ title })}>
        {isComplete && (
          <div className={b('relative')}>
            <div className={b('completeMessage')}>{completeMessage}</div>
          </div>
        )}
        {isComplete ? icon || <div className={b('checkIcon')} /> : children}
      </div>
    </>
  );
}

StepButton.defaultProps = {
  withLine: true,
  dashed: false,
  icon: null,
};

StepButton.propTypes = {
  isComplete: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  completeMessage: PropTypes.string.isRequired,
  withLine: PropTypes.bool,
  dashed: PropTypes.bool,
  icon: PropTypes.node,
};

export default StepButton;
