import React from 'react';
import b_ from 'b_';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getCurrentStep } from '../../Reducers/main/selectors';
import Button from '../Button';
import './styles.scss';

const b = b_.lock('Footer');

function Footer({ handleClick, isStopSale, isAlertBlocksLoading }) {
  const step = useSelector(getCurrentStep, shallowEqual);
  const { t } = useTranslation();

  return (
    <div className={b()}>
      <div className={b('container')}>
        {!isStopSale && !isAlertBlocksLoading && (
          <div className={b('step')}>
            <span>{t('footer.step')}</span>
            <div className={b('stepCounter')}>{step}/4</div>
          </div>
        )}
        <Button onClick={handleClick} className={b('faq')} type="primary">
          faq
        </Button>
      </div>
    </div>
  );
}

Footer.propTypes = {
  isStopSale: PropTypes.bool.isRequired,
  isAlertBlocksLoading: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default React.memo(Footer);
