import React from 'react';
import './styles.scss';
import Spinner from '../Spinner';

function LoaderOverlay() {
  return (
    <div className="LoaderOverlay">
      <Spinner />
    </div>
  );
}

export default LoaderOverlay;
