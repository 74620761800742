import React from 'react';
import b_ from 'b_';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import Steps from 'Containers/Steps';
import Page404 from 'Containers/Page404';
import './styles.scss';
import PrintVersion from '../../Components/PrintVersion';
import {isGamesmar} from '../../Utils/envDependent'

const b = b_.lock('App');

function App() {
  return (
    <div className={b()}>
      <div
        style={{
          backgroundImage: `url(../../static/background.${
            isGamesmar() ? 'png' : 'jpeg'
          })`,
        }}
        className={b('background')}
      />
      <div className={b('container')}>
        <PrintVersion />
        <HashRouter>
          <Switch>
            <Route path="/giftcode/:giftcode/:market?" component={Steps} />
            <Route path="/not-found" component={Page404} />
            <Route path="/" component={() => <Redirect to="/not-found" />} />
          </Switch>
        </HashRouter>
      </div>
    </div>
  );
}

export default App;
