export function getUserId() {
  return localStorage.getItem('store:userId');
}

export function removeUserId() {
  return localStorage.removeItem('store:userId');
}

export function setUserId(userId) {
  if (userId) localStorage.setItem('store:userId', userId);
}
