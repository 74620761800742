import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import b_ from 'b_';
import './styles.scss';
import cx from '../../Utils/cx';
import { getTitle } from 'Utils/envDependent';

const b = b_.lock('Button');

function Button({
  children,
  type,
  className,
  loading,
  onClick,
  ...buttonProps
}) {
  const ref = useRef(null);
  const handleClick = useCallback(() => {
    const childLink = ref.current.querySelector('a');

    if (childLink) {
      return childLink.click();
    }

    onClick && onClick();
  }, [onClick, ref]);

  const title = getTitle();

  console.log('title', title);

  return (
    <button
      className={cx(b({ [`${type}`]: true, loading }), b(`${title}`), className)}
      onClick={handleClick}
      ref={ref}
      {...buttonProps}
    >
      {children}
    </button>
  );
}

Button.defaultProps = {
  type: 'default',
  className: '',
  loading: false,
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['primary', 'link', 'default']),
  className: PropTypes.string,
  onClick: PropTypes.func,
  buttonProps: PropTypes.object,
  /** Reflects loading in the button */
  loading: PropTypes.bool,
};

export default Button;
