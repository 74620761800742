import * as Types from './types';

export const initialState = {
  error: null,
  isLoading: true,
  giftcode: null,
  step: 1,
  game: null,
  user: {
    payload: null,
    isLoading: false,
  },
  country: {
    payload: null,
    isLoading: false,
  },
  userAgreed: {
    payload: false,
    isLoading: false,
  },
  bot: null,
  friendRequest: {
    status: null,
    updatedAt: null,
  },
  transferStatus: null,
  phase: null,
  bonusCode: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Types.INC_STEP: {
      return { ...state, step: state.step + 1, error: null };
    }
    case Types.SET_ERROR: {
      return { ...state, error: action.payload, isLoading: false };
    }
    case Types.SET_MAIN_INFO: {
      return { ...state, ...action.payload, isLoading: false };
    }
    case Types.ACCOUNT_SUCCESS: {
      return {
        ...state,
        user: { isLoading: false, payload: action.payload },
        error: null,
      };
    }
    case Types.ACCOUNT_FAIL: {
      return {
        ...state,
        user: { isLoading: false, payload: null },
        error: action.payload,
      };
    }
    case Types.ACCOUNT_START: {
      return { ...state, user: { ...state.user, isLoading: true } };
    }
    case Types.ACCOUNT_RESET: {
      return { ...state, user: initialState.user };
    }
    case Types.CHANGE_ACCOUNT_SUCCESS: {
      const { game, giftcode } = state;
      return { ...initialState, game, giftcode, error: null, isLoading: false };
    }
    case Types.CHANGE_ACCOUNT_FAIL: {
      const { game, giftcode } = state;
      return {
        ...initialState,
        game,
        giftcode,
        error: action.payload,
        isLoading: false,
      };
    }
    case Types.COUNTRY_SUCCESS: {
      return {
        ...state,
        country: { isLoading: false, payload: action.payload },
        error: null,
      };
    }
    case Types.COUNTRY_FAIL: {
      return {
        ...state,
        country: { isLoading: false, payload: null },
        error: action.payload,
      };
    }
    case Types.COUNTRY_START: {
      return { ...state, country: { ...state.country, isLoading: true } };
    }
    case Types.COUNTRY_RESET: {
      return { ...state, country: initialState.country };
    }
    case Types.SET_USER_AGREE_START: {
      return {
        ...state,
        userAgreed: { ...state.userAgreed, isLoading: true },
      };
    }
    case Types.SET_USER_AGREE_SUCCESS: {
      return {
        ...state,
        userAgreed: { ...state.userAgreed, isLoading: false, payload: true },
        step: 3,
      };
    }
    case Types.SET_USER_AGREE_FAIL: {
      return {
        ...state,
        error: action.payload,
        userAgreed: { ...state.userAgreed, isLoading: false },
      };
    }
    case Types.SET_FRIEND_DATA: {
      const isFriendRequestDone =
        action.payload.friendRequest &&
        action.payload.friendRequest.status === 'DONE';
      const step = isFriendRequestDone && state.step === 3 ? 4 : state.step;
      const error =
        action.payload && action.payload.error ? action.payload.error : null;
      const phase =
        action.payload && action.payload.phase ? action.payload.phase : null;
      return { ...state, ...action.payload, error, step, phase };
    }
    case Types.SET_GIFT_DATA: {
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
}
