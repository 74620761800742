export default function displayPhase(ph) {
  switch (ph) {
    case 'New':
      return 'We are packaging your Steam Gift, please expect';
    case 'AdjustBalance':
      return "We're packaging your Steam Gift, it's almost done";
    case 'SendFriendRequest':
      return 'Our courier has added you as a friend on Steam';
    case 'CheckFriend':
      return 'Our courier has added you as a friend on Steam';
    case 'WaitFriendAccept':
      return 'Our courier has added you as a friend on Steam';
    case 'Prepare':
      return "We're handing you the game, just a little bit more";
    case 'Purchase':
      return "We're handing you the game, just a little bit more";
    case 'WaitGiftAccept':
      return 'Accept Steam Gift';
    case 'Verify':
      return '';
    case 'Cancel':
      return '';
    default:
      return '';
  }
}
