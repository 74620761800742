export default function displayError(error, email) {

  switch (error) {
    case 'FR_NOT_ACCEPTED':
      return `Please contact us in chat from 11:00 to 23:00 CET or ${email}`;
    case 'PURCH_CANCEL_FAILED':
      return `Please contact us in chat from 11:00 to 23:00 CET or ${email}`;
    case 'PURCH_REGION_PRICE_DIF':
      return `Please contact us in chat from 11:00 to 23:00 CET or ${email}`;
    case 'PURCH_CANCEL':
      return `Please contact us in chat from 11:00 to 23:00 CET or ${email}`;
    case 'PURCH_ERROR':
      return `Please contact us in chat from 11:00 to 23:00 CET or ${email}`;
    case 'PURCH_TX_ERR':
      return `Please contact us in chat from 11:00 to 23:00 CET or ${email}`;
    case 'PURCH_NOT_REFUNDABLE':
      return `Please contact us in chat from 11:00 to 23:00 CET or ${email}`;
    case 'PURCH_ALREADY_OWNS':
      return `Please contact us in chat from 11:00 to 23:00 CET or ${email}`;
    case 'PURCH_UNEXPECTED_INVENTORY':
      return `Please contact us in chat from 11:00 to 23:00 CET or ${email}`;
    case 'NO_ACCOUNT_AVAIL':
      return `Please contact us in chat from 11:00 to 23:00 CET or ${email}`;
    case 'ADMIN_CANCEL':
      return `Please contact us in chat from 11:00 to 23:00 CET or ${email}`;
    default:
      return `Please contact us in chat from 11:00 to 23:00 CET or ${email}`;
  }
}
