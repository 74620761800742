import b_ from 'b_';
import React from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import useTitle from '../../Utils/useTitle';

const b = b_.lock('SomethingWentWrong');

function SomethingWentWrong() {
  const { t } = useTranslation();
  useTitle(t('something_went_wrong.title'));

  return (
    <div className={b()}>
      <span>{t('something_went_wrong.message')}</span>
      &nbsp;
      <a className={b('link')} href="mailto:support@gamesmar.info">
        {t('something_went_wrong.message_link')}
      </a>
    </div>
  );
}

export default SomethingWentWrong;
