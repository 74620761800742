import moment from 'moment';

export function getAvailableAt(time_start) {
  return moment(time_start).add(3, 'm');
}

export function getDiff(availableAt) {
  return availableAt.diff(moment(), 's');
}

export function getCurrentMoment() {
  return moment();
}
