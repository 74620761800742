import React from 'react';
import displayPhase from '../../Utils/displayPhase';
import displayError from '../../Utils/displayError';
import Countdown from 'react-countdown';
import { LoadingOutlined, CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { getEmail } from 'Utils/envDependent';

const PrintPhase = ({ phase, error }) => {

  const getEmailToDisplay = getEmail();

  const renderer = ({ minutes, seconds, completed }) => {
    if (phase === 'Verify') {
      // Render a completed state
      return 'You have successfully received the game, we will be happy with the positive feedback!';
    } else if (phase === 'Cancel') {
      return 'You missed the Steam Gift or canceled it, please contact us on Live Chat';
      // Render a countdown

    } else if (!phase && !error) {
      return 'Sign in and complete form to receive game'
    } else if (error) {
      return displayError(error.title, getEmailToDisplay)
    } else if (completed) {
      return window.location.reload()
    } else {
      return <span>{minutes}:{seconds}</span>;
    }
  };

  return (
    <div
      className="printPhase"
      style={
        phase === 'Verify'
          ? { backgroundColor: '#bfffcb' }
          : phase === 'Cancel' || error
            ? { backgroundColor: 'rgb(255, 196, 196)' }
            : { backgroundColor: 'rgb(255, 250, 196)' }
      }
    >
      <div style={{ padding: '0 5px' }}>
        {phase === 'Verify' ? (
          <CheckOutlined style={{ color: '#07B226' }} />
        ) : phase === 'Cancel' || error ? (
          <ExclamationCircleOutlined style={{ color: 'red' }} />
        ) : (
          <LoadingOutlined
            style={{ padding: '0 10px', color: 'black' }}
          />
        )}
        {!error && displayPhase(phase)}
      </div>
      <div style={{ padding: '0 10px' }}>
        <Countdown
          date={
            phase === 'SendFriendRequest' ||
              phase === 'CheckFriend' ||
              phase === 'WaitFriendAccept'
              ? Date.now() + 10 * 60000
              : Date.now() + 3 * 60000
          }
          renderer={renderer}
        />
      </div>
    </div>
  );
};

export default PrintPhase;
