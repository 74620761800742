import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

function getPathLocalization() {
  if (process.env.REACT_APP_I18N_PATH) {
    return process.env.REACT_APP_I18N_PATH;
  }
  return '/locales/en/{{ns}}.json';
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    load: 'languageOnly',
    fallbackLng: ['en'],
    saveMissing: true,
    cleanCode: true,
    lowerCaseLng: true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: getPathLocalization(),
    },
  });

export default i18n;
