import React from 'react';
import b_ from 'b_';
import './styles.scss';
import '../../static/highlightTheme.css';
import PropTypes from 'prop-types';
import cx from '../../Utils/cx';
import Button from '../Button';
import { getEmail } from 'Utils/envDependent';

const b = b_.lock('Alert');

function getIcon(iconType) {
  return <div className={b('icon', { [iconType]: true })} />;
}

/**
 * Message output
 */
function Alert({ className, error, type: typeProp, style }) {
  const { code, title, type, description } = error;

  const getEmailToDisplay = getEmail();

  const typeString = type ? (type === 0 ? 'warning' : 'error') : typeProp;
  const textError = () => {
    return (
      <>
        Something went wrong. Please contact to us in chat from 11:00 to 23:00
        CET or
        <a
          style={{ color: '#000', marginLeft: 3 }}
          href={`mailto:${getEmailToDisplay}`}
        >
          {getEmailToDisplay}
        </a> and provide your Gift Link.
      </>
    );
  };

  return (
    <div className={cx(b({ [typeString]: true }), className)} style={style}>
      <div
        className={cx(
          b('container'),
          !(code || title) && b('container_description_only'),
          ''
        )}
      >
        { error && code === 106 || code === 109 ? (
          <div className={b('title')} style={{ marginBottom: 20 }}>
            <div className={b('title__block')}>
              {getIcon(typeString)}
              <div className={b('title__block__text')}>
                <div>
                  We are sorry, but we cannot transfer the game to you. contact
                  to us in chat from 11:00 to 23:00 CET or{' '}
                  <Button type="link">
                    <a
                      style={{ color: '#000', marginLeft: 3 }}
                      href={`mailto:${getEmailToDisplay}`}
                    >
                      {getEmailToDisplay}
                    </a>
                  </Button>{' '}
                  and provide your Gift Link.
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {error && code !== 106 && code !== 109 ? (
          <div className={b('title')} style={{ marginBottom: 20 }}>
            <div className={b('title__block')}>
              {getIcon(typeString)}
              <div className={b('title__block__text')}>
                {typeString === 'warning' ? description : textError()}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

Alert.defaultProps = {
  className: '',
  type: 'error',
  disableDebug: false,
};

Alert.propTypes = {
  className: PropTypes.string,
  error: PropTypes.object,
  /** Message type */
  type: PropTypes.oneOf(['error', 'warning']),
  /** To disable the display of service information */
  disableDebug: PropTypes.bool,
  style: PropTypes.object,
};

export default React.memo(Alert);
