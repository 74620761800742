import React from 'react';
import PropTypes from 'prop-types';
import b_ from 'b_';
import './styles.scss';
import cx from '../../Utils/cx';

const b = b_.lock('Checkbox');

function Checkbox({ value, onChange, className, children }) {
  return (
    <div className={cx(b(), className)} onClick={onChange}>
      <div className={b('box', { checked: value })} />
      {children}
    </div>
  );
}

Checkbox.defaultProps = {
  className: '',
};

Checkbox.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Checkbox;
