import React from 'react';
import PropTypes from 'prop-types';
import b_ from 'b_';
import { Trans, useTranslation } from 'react-i18next';
import Paragraph from '../Paragraph';
import './styles.scss';
import cx from '../../Utils/cx';
import { getEmail } from 'Utils/envDependent';

const b = b_.lock('ResendBlock');

function ResendBlock({ className }) {
  const { t } = useTranslation();

  const getEmailToDisplay = getEmail();
  return (
    <div className={cx(b(), className)}>
      <Paragraph className={b('text')}>
        <Trans
          i18nKey='controls.buttons.resend_block.0'
          values={{ email: getEmailToDisplay }}
        />
      </Paragraph>
    </div>
  );
}

ResendBlock.defaultProps = {
  className: '',
};

ResendBlock.propTypes = {
  className: PropTypes.string,
};

export default ResendBlock;
