import React from 'react';
import PropTypes from 'prop-types';
import b_ from 'b_';
import './styles.scss';
import cx from '../../Utils/cx';

const b = b_.lock('Title');

function Title({ level, className = '', children }) {
  return (
    <div className={cx(b({ [`level${level}`]: true }), className)}>
      {children}
    </div>
  );
}
Title.propTypes = {
  level: PropTypes.oneOf([1, 2]).isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Title;
