import React from 'react';
import PropTypes from 'prop-types';
import b_ from 'b_';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import cx from '../../Utils/cx';
import { getGiftCode } from '../../Reducers/main/selectors';
import './styles.scss';

const b = b_.lock('SignInButton');

function SignInSteam({ className }) {
  const { t } = useTranslation();
  const giftcode = useSelector(getGiftCode, shallowEqual);

  const authLink = `${process.env.REACT_APP_AUTH_LINK.replace(/hostname/g, window.location.host)}${giftcode}`;

  return (
    <Button type="primary" className={cx(b(), className)}>
      <div className={b('icon')} />
      <a
        className={b('link')}
        href={authLink}
      >
        {t('controls.buttons.sign_in_steam')}
      </a>
    </Button>
  );
}

SignInSteam.propTypes = {
  className: PropTypes.string,
};

export default SignInSteam;
