import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import b_ from 'b_';
import Title from '../../../Components/Title';
import StepButton from '../../../Components/StepButton';
import Paragraph from '../../../Components/Paragraph';
import Button from '../../../Components/Button';
import useBoolean from '../../../Utils/useBoolean';
import cx from '../../../Utils/cx';
import './styles.scss';
import LoaderOverlay from '../../../Components/LoaderOverlay';
import { getForCountryStep } from '../../../Reducers/main/selectors';
import CountrySelector from '../../../Components/CountrySelector';
import Card from '../../../Components/Card';
import Checkbox from '../../../Components/Checkbox';
import ChangeAccount from '../../../Components/ChangeAccount';
import {
  setUserAgree,
  selectCountry as actionSelectCountry,
} from '../../../Reducers/main/actions';
import Alert from '../../../Components/Alert';
import NextStep from '../../../Components/NextStep';

const b = b_.lock('SelectCountry');

function SelectCountry({ className = '', disabled }) {
  // console.log(`SelectCountry_disabled: ${disabled}`);
  const dispatch = useDispatch();
  const {
    userName,
    country: { payload, isLoading },
    error,
    userAgreed,
  } = useSelector(getForCountryStep, shallowEqual);
  const { t } = useTranslation();

  const [country, setCountry] = useState(payload);

  const { value: firstCheckbox, toggleValue: toggleFirstCheckbox } = useBoolean(
    userAgreed.payload
  );
  const {
    value: secondCheckbox,
    toggleValue: toggleSecondCheckbox,
  } = useBoolean(userAgreed.payload);
  const { value: thirdCheckbox, toggleValue: toggleThirdCheckbox } = useBoolean(
    userAgreed.payload
  );

  const handleNextStep = useCallback(() => {
    dispatch(setUserAgree());
  }, [dispatch]);

  const selectCountry = useCallback((k) => setCountry({ ...k }), []);
  const nextStep =
    !!country && firstCheckbox && secondCheckbox && thirdCheckbox;

  useEffect(() => {
    if (country) dispatch(actionSelectCountry(country));
  }, [dispatch, country]);

  return (
    <div className={cx(b(), className)}>
      <StepButton
        isComplete={userAgreed.payload}
        completeMessage={t('main.steps.two.step_button_message')}
      >
        2
      </StepButton>
      {(isLoading || userAgreed.isLoading) && <LoaderOverlay />}
      <div className={b('content', { disabled: disabled })}>
        <Title level={2} className={b('title')}>
          {t('main.steps.two.select_store_country')}
        </Title>
        {error && !disabled && <Alert className={b('error')} error={error} />}
        <Paragraph className={b('text')}>
          <Trans
            i18nKey="main.steps.two.description"
            components={[<a type="link" style={{ color: "#fff", fontSize: "16px", fontWeight: 600 }} href="https://store.steampowered.com/account/store_transactions/">_</a>]}
          />
        </Paragraph>
        <CountrySelector
          className={b('search')}
          onChange={selectCountry}
          value={country}
          placeholder={t('controls.input_placeholder.select_steam_country')}
        />
        {!!country && !isLoading && (
          <Card className={b('card')}>
            <Paragraph className={b('cardTitle')}>
              {t('main.steps.two.select.title')}
            </Paragraph>
            <Checkbox
              className={b('checkbox')}
              onChange={toggleFirstCheckbox}
              value={firstCheckbox}
            >
              <Paragraph>
                <Trans
                  i18nKey="main.steps.two.select.options.0"
                  values={{ userName }}
                  components={[<span className="bold"></span>]}
                />
              </Paragraph>
            </Checkbox>
            <Checkbox
              className={b('checkbox')}
              onChange={toggleSecondCheckbox}
              value={secondCheckbox}
            >
              <Paragraph>
                <Trans
                  i18nKey="main.steps.two.select.options.1"
                  components={[<span className="bold"></span>]}
                />
              </Paragraph>
            </Checkbox>
            <Checkbox onChange={toggleThirdCheckbox} value={thirdCheckbox}>
              <Paragraph>
                <Trans
                  i18nKey="main.steps.two.select.options.2"
                  components={[<span className="bold"></span>]}
                />
              </Paragraph>
            </Checkbox>
          </Card>
        )}
        <div className="GroupButton">
          <NextStep
            className={b('nextStep')}
            disabled={!nextStep}
            onClick={handleNextStep}
          />
          <ChangeAccount />
        </div>
      </div>
    </div>
  );
}

SelectCountry.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

export default SelectCountry;
