import * as Types from './types';
import { getUserId, removeUserId, setUserId } from '../../Utils/localStorage';
import * as Moment from '../../Utils/Moment';

const initialState = {
  error: null,
  isLoading: true,
  giftcode: null,
  step: 1,
  game: null,
  eod: null,
  user: {
    payload: null,
    isLoading: false,
    error: null,
  },
  location: null,
  userAgreed: {
    payload: false,
    isLoading: false,
  },
  country: {
    payload: null,
    isLoading: false,
    error: null,
  },
  bot: null,
  friendRequest: {
    status: null,
    updatedAt: null,
  },
  transferStatus: null,
  phase: null,
  bonusCode: null,
};

const LAST_STEP_INTERVAL = 15 * 1000;

function buildPayload(data, giftcode) {
  const result = { ...initialState, game: data.game, giftcode };

  if (data.location) {
    result.location = data.location;
  }

  if (data.eod) {
    result.eod = data.eod;
  }

  if (data.userAgreed) {
    result.userAgreed.payload = data.userAgreed;
  }

  if (data.country) {
    result.country.payload = data.country;
  }

  if (data.user) {
    result.user.payload = data.user;
    result.step = 2;
  } else {
    return result;
  }

  if (data.country && data.userAgreed) {
    result.step = 3;
  } else {
    return result;
  }

  if (data.bot) {
    result.bot = data.bot;
  } else {
    return result;
  }

  if (data.friendRequest) {
    result.friendRequest = data.friendRequest;

    if (data.friendRequest.status === 'DONE') {
      result.step = 4;
    }
  } else {
    return result;
  }

  if (data.transferStatus) {
    result.transferStatus = data.transferStatus;
  }

  if (data.phase) {
    result.phase = data.phase;
  }

  if (data.bonusCode) {
    result.bonusCode = data.bonusCode;
  }
  return result;
}

export const firstLoading = (giftcode, market) => (
  dispatch,
  getState,
  { main }
) => {
  // Получаем id юзера из ls
  const userId = null;
  const data = (userId && { user: { id: userId } }) || null;
  // console.log('userId', userId);
  // console.log('initData', data);

  main
    .fetchMain(giftcode, market, data)
    .then((data) => {
      const payload = buildPayload(data, giftcode);
      // console.log('payloadData', data);
      // console.log('PAYLOAD', payload);
      // if (payload.user && payload.user.payload && payload.user.payload.id) {
      //   setUserId(payload.user.payload.id);
      // }
      dispatch({
        type: Types.SET_MAIN_INFO,
        payload,
      });
    })
    .catch((error) => {
      dispatch({
        type: Types.SET_ERROR,
        payload: error.error,
      });
    });
};

export const activateAccount = (url) => (dispatch, getState, { main }) => {
  const { giftcode } = getState().main;

  dispatch({
    type: Types.ACCOUNT_START,
  });

  main
    .sendUrl(giftcode, url)
    .then(({ user }) => {
      // setUserId(user.id);
      dispatch({
        type: Types.ACCOUNT_SUCCESS,
        payload: user,
      });
    })
    .catch((error) => {
      dispatch({
        type: Types.ACCOUNT_FAIL,
        payload: error.error,
      });
    });
};

export const selectCountry = (code) => (dispatch, getState, { main }) => {
  const { giftcode, country } = getState().main;
  const isCountryNotSelected =
    (country && country.payload && country.payload.code) !==
    (code && code.code);

  if (isCountryNotSelected) {
    dispatch({
      type: Types.COUNTRY_START,
    });

    main
      .setCountry(giftcode, code.code)
      .then((payload) => {
        dispatch({
          type: Types.COUNTRY_SUCCESS,
          payload,
        });
      })
      .catch((error) => {
        dispatch({
          type: Types.COUNTRY_FAIL,
          payload: error.error,
        });
      });
  }
};

export const setUserAgree = () => (dispatch, getState, { main }) => {
  dispatch({
    type: Types.SET_USER_AGREE_START,
  });
  const { giftcode } = getState().main;
  return main
    .setUserAgree(giftcode)
    .then((resp) => {
      dispatch({
        type: Types.SET_USER_AGREE_SUCCESS,
      });
    })
    .catch((e) => {
      dispatch({
        type: Types.SET_USER_AGREE_FAIL,
        payload: e.error && e.error,
      });
    });
};

export function incStep() {
  return {
    type: Types.INC_STEP,
  };
}

export const changeAccount = () => (dispatch, getState, { main }) => {
  const { giftcode } = getState().main;
  return main
    .changeAccount(giftcode)
    .then((resp) => {
      removeUserId();
      dispatch({
        type: Types.CHANGE_ACCOUNT_SUCCESS,
      });
    })
    .catch((e) => {
      dispatch({
        type: Types.CHANGE_ACCOUNT_FAIL,
        payload: e.error && e.error,
      });
    });
};

export const getStatusRequest = () => (dispatch, getState, { main }) => {
  const { giftcode } = getState().main;
  const updatedAt = new Date().getTime();
  dispatch({
    type: Types.SET_FRIEND_DATA,
    payload: { friendRequest: { updatedAt, status: null }, bot: null },
  });

  const availableAt = Moment.getAvailableAt(updatedAt);

  function resendRequest() {
    main
      .getFriendRequest(giftcode)
      .then(({ error, friendRequest, bot, phase }) => {
        const isDone = friendRequest && friendRequest.status === 'DONE';
        const isError = error;
        const payload = { error };

        if (bot) {
          payload.bot = bot;
        }

        if (friendRequest) {
          payload.friendRequest = friendRequest;
        }

        if (phase) {
          payload.phase = phase;
        }
        dispatch({
          type: Types.SET_FRIEND_DATA,
          payload,
        });
        if (
          isDone ||
          (isError && isError.type === 1) ||
          !availableAt.isAfter(Moment.getCurrentMoment())
        ) {
          return;
        }
        setTimeout(() => resendRequest(), 15000);
      })
      .catch((error) => {
        dispatch({
          type: Types.SET_FRIEND_DATA,
          payload: error && error.error,
        });
      });
  }

  resendRequest();
};

export const getGiftData = () => (dispatch, getState, { main }) => {
  let intervalID = null;
  const { giftcode, transferStatus } = getState().main;
  // const isDone = transferStatus === 'DONE';
  // if (isDone) return;

  function sendRequest() {
    main
      .getGame(giftcode)
      .then(({ transferStatus, bonusCode, error, phase }) => {
        const payload = { error };
        if (transferStatus) {
          payload.transferStatus = transferStatus;
        }
        if (transferStatus) {
          payload.bonusCode = bonusCode;
        }
        if (phase) {
          payload.phase = phase;
        }

        dispatch({
          type: Types.SET_FRIEND_DATA,
          payload,
        });

        if (transferStatus === 'DONE') {
          return clearInterval(intervalID);
        }
      })
      .catch((error) => {
        dispatch({
          type: Types.SET_FRIEND_DATA,
          payload: error.error,
        });
      });
  }

  intervalID = setInterval(() => {
    sendRequest();
  }, LAST_STEP_INTERVAL);
};
