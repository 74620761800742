import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import b_ from 'b_';
import './styles.scss';
import Title from '../../../Components/Title';
import Alert from '../../../Components/Alert';
import StepButton from '../../../Components/StepButton';
import Button from '../../../Components/Button';
import Spinner from '../../../Components/Spinner';
import cx from '../../../Utils/cx';
import displayPhase from '../../../Utils/displayPhase';
import { getTransferStatus } from '../../../Reducers/main/selectors';
import { getGiftData } from '../../../Reducers/main/actions';
import Paragraph from '../../../Components/Paragraph';
import Card from '../../../Components/Card';
import PrintPhase from 'Components/PrintPhase';

const b = b_.lock('GetGame');

function GetGame({ className = '', disabled }) {

  const { phase, transferStatus, error, userId } = useSelector(
    getTransferStatus,
    shallowEqual
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getGiftData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log('phase Get Game =>', phase)

  return (
    <div className={cx(b(), className)}>
      <StepButton
        completeMessage={t('main.steps.four.step_button_message')}
        isComplete={transferStatus === 'DONE'}
        dashed
        icon={<div className={b('iconStar')} />}
      >
        4
      </StepButton>
      <Title className={b('title')} level={2}>
        {t('main.steps.four.title')}
      </Title>
      {error && transferStatus === 'ERROR' ? (
        <Alert className={b('error')} error={error} />
      ) : (
        <>
          {transferStatus === 'DONE' ? (
            <Card>
              <Paragraph>
                {t('main.steps.four.you_accepted_your_game')}
              </Paragraph>
            </Card>
          ) : (
            <>
              <Alert
                type="warning"
                error={{
                  description:
                    transferStatus === 'PROCESSING'
                      ? t('main.steps.four.wait_message')
                      : t('main.steps.four.please_wait'),
                }}
                disableDebug
                style={{ marginBottom: '20px' }}
              />
              <Button type="primary" loading={transferStatus !== 'PROCESSING'}>
                <div
                  className={b('buttonContent', {
                    isReceived: transferStatus === 'DONE',
                  })}
                >
                  {transferStatus === 'PROCESSING' ? (
                    <a
                      className={b('link')}
                      target="_blank"
                      rel="noreferrer noopener"
                      href={`https://steamcommunity.com/profiles/${userId}/inventory/#pending_gifts`}
                    >
                      {t('controls.buttons.accept_game')}
                    </a>
                  ) : (
                    <Spinner size="small" />
                  )}
                </div>
              </Button>

            </>
          )}
        </>
      )}
      <div style={{ marginTop: '10px' }}><PrintPhase phase={phase} error={error} /></div>
    </div>
  );
}

GetGame.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

export default GetGame;
