export function getDomainUrl() {
  return new URL(window.location.href).origin;
}

export function isGamesmar() {
  return new URL(window.location.href).origin.includes('gamesmar');
}

export function getTitle() {
  if (isGamesmar()) {
    return 'gamesmar';
  }
  return 'battlekeys';
}

export function getEmail() {
  if (isGamesmar()) {
    return 'gift@gamesmar.info';
  }
  return 'gift@battlekeys.com';
}
