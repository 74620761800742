import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from '../../Utils/cx';
import Button from '../Button';
import { getUserId } from '../../Reducers/main/selectors';
import './styles.scss';

/** Button to accept the invitation */
function AcceptInvitation({ className }) {
  const userId = useSelector(getUserId, shallowEqual);
  const { t } = useTranslation();

  return (
    <Button className={cx(className, 'AcceptInvitation')} type="primary">
      <a
        target="_blank"
        rel="noreferrer noopener"
        className="AcceptInvitation__link"
        href={`https://steamcommunity.com/profiles/${userId}/friends/pending`}
      >
        {t('controls.buttons.accept_invitation')}
      </a>
    </Button>
  );
}

AcceptInvitation.defaultProps = {
  className: '',
};

AcceptInvitation.propTypes = {
  className: PropTypes.string,
};

export default React.memo(AcceptInvitation);
