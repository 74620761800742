import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import cx from '../../Utils/cx';

function Card({ children, className }) {
  return <div className={cx('Card', className)}>{children}</div>;
}

Card.defaultProps = {
  className: '',
};

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Card;
