export const GAME_ERROR = 'core/game_error';
export const GAME_SUCCESS = 'core/game_success';

export const COUNTRY_ERROR = 'core/country_error';
export const COUNTRY_SUCCESS = 'core/country_success';

export const ALERT_BLOCK_ERROR = 'core/alert_block_error';
export const ALERT_BLOCK_SUCCESS = 'core/alert_block_success';

export const STOP_SALE_CHANGE = 'core/stop_sale_change';
