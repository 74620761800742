import * as Types from './types';

export const loadCountry = () => (dispatch, getState, api) => {
  const { giftcode } = getState().main;
  return api.core
    .loadCounties(giftcode)
    .then((payload) => {
      dispatch({
        type: Types.COUNTRY_SUCCESS,
        payload,
      });
    })
    .catch((payload) => {
      dispatch({
        type: Types.COUNTRY_ERROR,
        payload,
      });
    });
};

export const loadAlertBlock = () => (dispatch, getState, api) => {
  const { giftcode } = getState().main;
  return api.core
    .loadAlertBlock(giftcode)
    .then((payload) => {
      dispatch({
        type: Types.ALERT_BLOCK_SUCCESS,
        payload,
      });
    })
    .catch((payload) => {
      dispatch({
        type: Types.ALERT_BLOCK_ERROR,
        payload,
      });
    });
};

export const stopSaleChange = (value) => (dispatch) => {
  dispatch({
    type: Types.STOP_SALE_CHANGE,
    payload: value,
  });
};
