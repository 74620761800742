import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import rootReducer from 'Reducers';
import api from 'Services';

export default function(initialState = {}) {
  let middleware = applyMiddleware(
    promiseMiddleware,
    thunk.withExtraArgument(api)
  );
  return createStore(rootReducer, initialState, compose(middleware));
}
