import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import b_ from 'b_';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import Title from '../../../Components/Title';
import Card from '../../../Components/Card';
import SteamAvatar from '../../../Components/SteamAvatar';
import Paragraph from '../../../Components/Paragraph';
import StepButton from '../../../Components/StepButton';
import ResendBlock from '../../../Components/ResendBlock';
import Alert from '../../../Components/Alert';
import cx from '../../../Utils/cx';
import { getFriendRequestData } from '../../../Reducers/main/selectors';
import { getGiftData, getStatusRequest } from '../../../Reducers/main/actions';
import AcceptInvitation from '../../../Components/AcceptInvitation';
import PrintPhase from '../../../Components/PrintPhase';
// import displayPhase from '../../../Utils/displayPhase';
// import Countdown from 'react-countdown';
// import { LoadingOutlined, CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const b = b_.lock('FriendRequest');

function FriendRequest({ className = '', disabled }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getStatusRequest());
  }, [dispatch]);

  const handleResend = useCallback(() => {
    dispatch(getStatusRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getGiftData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { phase, friendRequest, bot, error } = useSelector(
    getFriendRequestData,
    shallowEqual
  );

  console.log('phase Friend =>', phase)

  return (
    <div className={cx(b(), className)}>
      <StepButton
        completeMessage={t('main.steps.three.step_button_message')}
        isComplete={friendRequest.status === 'DONE'}
      >
        3
      </StepButton>
      <div className={b('content', { disabled: disabled })}>
        <Title className={b('title')} level={2}>
          {t('main.steps.three.title')}
        </Title>
        {error && !disabled && <Alert className={b('error')} error={error} />}
        {bot && friendRequest.status !== 'DONE' && (
          <Alert
            type="warning"
            error={{ description: t('main.steps.three.wait_message') }}
            disableDebug
            style={{ marginBottom: '20px' }}
          />
        )}
        <Card className={b('card')}>
          <div className={b('botInfo')}>
            {bot ? (
              <>
                <SteamAvatar className={b('botAvatar')} avatar={bot.avatar} />
                <Paragraph>
                  <span className={b('botName')}>{bot.name}</span>
                  {friendRequest.status === 'DONE'
                    ? t('main.steps.three.request_status.0')
                    : t('main.steps.three.request_status.1')}
                </Paragraph>
              </>
            ) : (
              <Paragraph>{t('main.steps.three.description')}</Paragraph>
            )}
          </div>
        </Card>
        <ResendBlock
          className={b('resend')}
          updatedAt={friendRequest.updatedAt}
          handleResend={handleResend}
        />
        {friendRequest.status !== 'DONE' && (
          <>
            {!error && bot && (
              <AcceptInvitation className={b('acceptButton')} />
            )}
          </>
        )}
        {friendRequest.status !== 'DONE' && (
          <PrintPhase phase={phase} error={error} />
        )}
      </div>
    </div>
  );
}

FriendRequest.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

export default FriendRequest;
