import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import cx from '../../Utils/cx';
import { getTitle } from 'Utils/envDependent';

function SteamAvatar({ avatar, className }) {

  const title = getTitle();
  return (
    <div
      className={cx('SteamAvatar', title, className)}
      style={{ backgroundImage: `url(${avatar})` }}
    />
  );
}

SteamAvatar.defaultProps = {
  className: '',
};

SteamAvatar.propTypes = {
  avatar: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default SteamAvatar;
