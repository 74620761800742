/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import b_ from 'b_';
import ImageView from '../../Components/ImageView';
import Title from '../../Components/Title';
import Button from '../../Components/Button';
import Paragraph from '../../Components/Paragraph';
import { getIsStopSale, getAlertBlocks } from '../../Reducers/core/selectors';
import { firstLoading } from '../../Reducers/main/actions';
import {
  getGameInformation,
  getIsLoading,
  getStepControl,
} from '../../Reducers/main/selectors';
import './styles.scss';
import Footer from '../../Components/Footer';
import Spinner from '../../Components/Spinner';
import ExtraStep from './ExtraStep';
import GetGame from './GetGame';
import FriendRequest from './FriendRequest';
import SelectCountry from './SelectCountry';
import SingInSteam from './SingInSteam';
import useTitle from '../../Utils/useTitle';
import Error from '../SomethingWentWrong';
import FAQ from '../FAQ';
import useBoolean from '../../Utils/useBoolean';

function Steps() {
  const { step, bonus, transferStatus } = useSelector(
    getStepControl,
    shallowEqual
  );
  const steps = [SingInSteam, SelectCountry, FriendRequest, GetGame];

  return (
    <div className="Steps">
      {steps.slice(0, step).map((Step, index) => (
        <Step
          className="Steps__step"
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          disabled={index + 1 !== step}
        />
      ))}
      {transferStatus === 'DONE' && bonus && <ExtraStep bonusCode={bonus} />}
    </div>
  );
}

const b = b_.lock('Home');

function Home({ match }) {
  const { t } = useTranslation();
  const {
    value: isOpen,
    setFalse: close,
    toggleValue: handleClick,
  } = useBoolean(false);
  const isLoading = useSelector(getIsLoading, shallowEqual);
  const { isLoading: isAlertBlocksLoading } = useSelector(
    getAlertBlocks,
    shallowEqual
  );
  const isStopSale = useSelector(getIsStopSale, shallowEqual);
  const { game, eod } = useSelector(getGameInformation, shallowEqual);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(firstLoading(match.params.giftcode))
  }, [dispatch, match.params.giftcode]);
  const title = useMemo(
    () => `${t('page_title.main')} ${game ? game.name : ''}`,
    [game, t]
  );

  useTitle(title);
  const dateEod = new Date(eod);
  let dateNow = new Date();

  dateNow = Date.UTC(
    dateNow.getFullYear(),
    dateNow.getMonth(),
    dateNow.getDate(),
    dateNow.getHours(),
    dateNow.getMinutes(),
    dateNow.getSeconds()
  );

  return (
    <div className={b({ loadingContainer: isLoading })}>
      {isOpen && <FAQ handleClose={close} />}
      {isLoading ? (
        <Spinner size="large" />
      ) : game ? (
        <>
          <div className={b('generalInfo')}>
            <ImageView className={b('image')} image={game.thumbnail} />
            <Title className={b('title')} level={1}>
              {t('main.steps.description.title')}
            </Title>
            <Paragraph className={b('text')}>
              <div>
                <Trans
                  i18nKey="main.steps.description.paragraph.0"
                  components={[<b></b>]}
                />
              </div>
              {eod ? (
                <div className={b('Deadline')}>
                  Please activate this game within {dateEod.getDate(eod)} DD{' '}
                  {dateEod.getHours(eod) - 2} HH {dateEod.getMinutes(eod)} MM
                </div>
              ) : null}
              <Trans
                i18nKey="main.steps.description.paragraph.2"
                values={{ region: game.region, name: game.name }}
                components={[
                  // eslint-disable-next-line
                  <b></b>,
                  <Button type="link" onClick={handleClick}>
                    FAQ
                  </Button>,
                  <Button type="link">_</Button>,
                ]}
              />
            </Paragraph>
            <div className={b('time')}>
              <div className={b('iconClock')} />
              <Paragraph>
                <Trans
                  i18nKey="main.steps.description.paragraph.3"
                  // eslint-disable-next-line react/self-closing-comp
                  components={[<b></b>]}
                />
              </Paragraph>
            </div>
          </div>
          <Steps />
          <Footer
            handleClick={handleClick}
            isStopSale={isStopSale}
            isAlertBlocksLoading={isAlertBlocksLoading}
          />
        </>
      ) : (
        <Error />
      )}
    </div>
  );
}

Home.propTypes = {
  match: PropTypes.object,
};

export default Home;
