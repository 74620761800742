import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import SearchSelect from '../SearchSelect';
import { loadCountry } from '../../Reducers/core/actions';

function CountrySelector({ value, onChange, className, placeholder }) {
  const countries = useSelector(
    (state) => state.core.countries.payload || [],
    shallowEqual
  );
  const location = useSelector(
    (state) => state.main.location || {},
    shallowEqual
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCountry());
  }, [dispatch]);

  useEffect(() => {
    countries &&
      countries.some((value) => {
        if (value && (value && value.code) === (location && location.code)) {
          onChange({ ...location });
          return true;
        }
        return false;
      });
  }, [countries, location, onChange]);

  return (
    <SearchSelect
      className={className}
      onChange={onChange}
      options={countries}
      value={value}
      placeholder={placeholder}
    />
  );
}

CountrySelector.defaultProps = {
  className: '',
};

CountrySelector.propTypes = {
  value: PropTypes.shape({
    code: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default CountrySelector;
