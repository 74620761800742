import ApiService from './ApiService';

const mockLoadAlertBlock = [
  {
    title: 'PRIMARY',
    body: 'BODY',
    type: 'primary',
  },
  {
    title: 'SUCCESS',
    body: 'BODY',
    type: 'success',
  },
  {
    title: 'DANGER',
    body: 'BODY',
    type: 'danger',
  },
  {
    title: 'WARNING',
    body: 'BODY',
    type: 'warning',
  },
  {
    title: 'INFO',
    body: 'BODY',
    type: 'info',
  },
  {
    title: 'LIGHT',
    body: 'BODY',
    type: 'light',
  },
  {
    title: 'STOPSALE',
    body: 'BODY',
    type: 'danger',
    stopSale: true,
  },
];

export default {
  loadCounties(giftcode = '') {
    return ApiService.get(`/countries/${giftcode}`);
  },
  loadAlertBlock(giftcode = '') {
    return ApiService.get(`/alert-block/${giftcode}`);

    // return new Promise((resolve) => {
    //   setTimeout(resolve, 1000, mockLoadAlertBlock);
    // });
  },
};
