export const SET_MAIN_INFO = 'main/set-info';
export const INC_STEP = 'main/inc-step';
export const SET_ERROR = 'main/set-error';

export const ACCOUNT_SUCCESS = 'main/account-success';
export const ACCOUNT_FAIL = 'main/account-fail';
export const ACCOUNT_RESET = 'main/account-reset';
export const ACCOUNT_START = 'main/account-start';

export const COUNTRY_SUCCESS = 'main/country-success';
export const COUNTRY_FAIL = 'main/country-fail';
export const COUNTRY_RESET = 'main/country-reset';
export const COUNTRY_START = 'main/country-start';

export const SET_USER_AGREE_START = 'main/set-user-agree-start';
export const SET_USER_AGREE_SUCCESS = 'main/set-user-agree-success';
export const SET_USER_AGREE_FAIL = 'main/set-user-agree-fail';

export const CHANGE_ACCOUNT_SUCCESS = 'main/change-account-success';
export const CHANGE_ACCOUNT_FAIL = 'main/change-account-fail';

export const SET_FRIEND_DATA = 'main/friend-data';

export const SET_GIFT_DATA = 'main/gift-data';
