import qs from 'qs';

const {
  REACT_APP_API_PREFIX: API_PREFIX,
  REACT_APP_API_VERSION: API_VERSION,
} = process.env;
const CONTENT_TYPE_JSON = 'application/json';

export function prepareQueryString(params) {
  return qs.stringify(params, { encode: false, arrayFormat: 'brackets' });
}

export function parseQueryString(queryString) {
  return qs.parse(queryString, { ignoreQueryPrefix: true });
}

class ApiService {
  constructor(apiPrefix = '', apiVersion = '') {
    this.apiPrefix = apiPrefix;
    this.apiVersion = apiVersion;
  }

  getApiLink(link, params) {
    return (
      this.apiPrefix +
      this.apiVersion +
      link +
      (params ? '?' + prepareQueryString(params) : '')
    );
  }

  call(url, method = 'GET', options = {}, params = null) {
    const headers = options.headers || {};
    headers['X-Requested-With'] = 'XMLHttpRequest';

    for (let headerKey in options.headers || {}) {
      if (options.headers.hasOwnProperty(headerKey)) {
        headers[headerKey] = options.headers[headerKey];
      }
    }

    options.headers = headers;
    options.method = method;
    options.credentials = 'include';
    options.mode = 'cors';

    return fetch(this.getApiLink(url, params), options)
      .then((resp) => {
        if (resp.status === 204) {
          return [{}, resp.status];
        }
        let result;

        if (resp.headers.get('Content-Type') && resp.headers.get('Content-Type').includes(CONTENT_TYPE_JSON)) {
          result = resp.json();
        } else {
          result = resp.text();
        }

        return Promise.all([result, resp.status]);
      })
      .then(([data, status]) => {
        if (status >= 500 || [400, 401, 402, 403, 404].includes(status)) {
          return Promise.reject(data);
        }

        return data;
      });
  }

  get(url, params = null, options = {}) {
    return this.call(url, 'GET', options, params);
  }

  post(url, data = null, options = {}) {
    if (data) {
      options.body = JSON.stringify(data);
      options.headers = {
        'Content-Type': CONTENT_TYPE_JSON,
      };
    }

    return this.call(url, 'POST', options);
  }

  put(url, data = null, options = {}) {
    if (data) {
      options.body = JSON.stringify(data);
      options.headers = {
        'Content-Type': CONTENT_TYPE_JSON,
      };
    }

    return this.call(url, 'PUT', options);
  }

  upload(url, file, name) {
    const formData = new FormData();
    formData.append(name, file);

    const options = {
      body: formData,
    };

    return this.call(url, 'POST', options);
  }

  delete(url) {
    return this.call(url, 'DELETE');
  }
}

export default new ApiService(``, API_VERSION);
