import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import { incStep } from '../../Reducers/main/actions';

function NextStep({ className, disabled, onClick }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const callback = useMemo(() => {
    const defaultCallback = () => dispatch(incStep());
    return onClick || defaultCallback;
  }, [dispatch, onClick]);

  return (
    <Button
      className={className}
      type="primary"
      onClick={callback}
      disabled={disabled}
    >
      {t('controls.buttons.next_step')}
    </Button>
  );
}

NextStep.defaultProps = {
  disabled: false,
  onClick: null,
  className: '',
};

NextStep.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default React.memo(NextStep);
