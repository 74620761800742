import React from 'react';
import b_ from 'b_';
import PropTypes from 'prop-types';
import './styles.scss';
import cx from '../../Utils/cx';

const b = b_.lock('Input');

function Input({ className, type, valid, ...inputProps }) {
  return (
    <div className={cx(b({ [type]: !!type }), className)}>
      {type === 'search' && <div className={b('iconSearch')} />}
      <input
        className={b('input', { [type]: !!type, inValid: !valid })}
        {...inputProps}
      />
    </div>
  );
}

Input.defaultProps = {
  className: '',
  valid: true,
};

Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['search']),
  valid: PropTypes.bool,
  inputProps: PropTypes.object,
};

export default Input;
