import React, { useMemo } from 'react';
import b_ from 'b_';
import { Trans, useTranslation } from 'react-i18next';
import { getEmail } from 'Utils/envDependent';
import Button from '../../Components/Button';
import './styles.scss';


const b = b_.lock('FAQ');

function FAQ({ handleClose }) {
  const { t } = useTranslation();

  const getEmailToDisplay = getEmail();

  return (
    <div className={b()}>
      <div className={b('close')} onClick={handleClose} />
      <div className={b('title')}>FAQ</div>
      <div className={b('container')}>
        <div className={b('item')}>
          <div className={b('item__title')}>{t('faq.data.0.title')}</div>
          <div>{t('faq.data.0.data')}</div>
        </div>
        <div className={b('item')}>
          <div className={b('item__title')}>{t('faq.data.1.title')}</div>
          <div>
            <Trans
              i18nKey="faq.data.1.data"
              values={{ email: getEmailToDisplay }}
              children={[
                <Button type="link" className={b('link')}>
                  <a className={b('a')} href={`mailto:${getEmailToDisplay}`}></a>
                </Button>,
              ]}
            />
          </div>
        </div>
        <div className={b('item')}>
          <div className={b('item__title')}>{t('faq.data.2.title')}</div>
          <div>{t('faq.data.2.data.0')}</div>
          <div>
            <Trans
              i18nKey="faq.data.2.data.1"
              values={{ email: getEmailToDisplay }}
              components={[
                <Button type="link" className={b('link')}>
                  <a className={b('a')} href={`mailto:${getEmailToDisplay}`}></a>
                </Button>,
              ]}
            />
          </div>
        </div>
        <div className={b('item')}>
          <div className={b('item__title')}>{t('faq.data.3.title')}</div>
          <div>{t('faq.data.3.data.0')}</div>
          <div>
            <Trans
              i18nKey="faq.data.3.data.1"
              values={{ email: getEmailToDisplay }}
              components={[
                <Button type="link" className={b('link')}>
                  <a className={b('a')} href={`mailto:${getEmailToDisplay}`}></a>
                </Button>,
              ]}
            />
          </div>
        </div>
        <div className={b('item')}>
          <div className={b('item__title')}>{t('faq.data.4.title')}</div>
          <div>{t('faq.data.4.data')}</div>
        </div>
        <div className={b('item')}>
          <div className={b('item__title')}>{t('faq.data.5.title')}</div>
          <div>{t('faq.data.5.data')}</div>
        </div>
        <div className={b('item')}>
          <div className={b('item__title')}>
            <div>{t('faq.data.6.title.0')}</div>
            <div>{t('faq.data.6.title.1')}</div>
          </div>
          <div>
            <Trans
              i18nKey="faq.data.6.data"
              values={{ email: getEmailToDisplay }}
              components={[
                <Button type="link" className={b('link')}>
                  <a className={b('a')} href={`mailto:${getEmailToDisplay}`}></a>
                </Button>,
              ]}
            />
          </div>
        </div>
        <div className={b('item')}>
          <div className={b('item__title')}>{t('faq.data.7.title')}</div>
          <div>{t('faq.data.7.data')}</div>
        </div>
        <div className={b('item')}>
          <div className={b('item__title')}>{t('faq.data.8.title')}</div>
          <div>
            <Trans
              i18nKey="faq.data.8.data"
              components={[
                <Button type="link" className={b('link')}>
                  <a
                    className={b('a')}
                    href="https://steamcommunity.com/games/593110/announcements/detail/1301948399254001159"
                  >
                    changes
                  </a>
                </Button>,
              ]}
            />
          </div>
        </div>
        <div className={b('item')}>
          <div className={b('item__title')}>{t('faq.data.9.title')}</div>
          <div>
            <Trans
              i18nKey="faq.data.9.data"
              components={[
                <Button type="link" className={b('link')}>
                  <a
                    className={b('a')}
                    href="https://steamcommunity.com/games/593110/announcements/detail/1301948399254001159"
                  >
                    changes
                  </a>
                </Button>,
              ]}
            />
          </div>
        </div>
        <div className={b('item')}>
          <div className={b('item__title')}>{t('faq.data.10.title')}</div>
          <div>
            <Trans
              i18nKey="faq.data.10.data"
              values={{ email: getEmailToDisplay }}
              components={[
                <Button type="link" className={b('link')}>
                  <a className={b('a')} href={`mailto:${getEmailToDisplay}`} />
                </Button>,
              ]}
            />
          </div>
        </div>
        <div className={b('item')}>
          <div className={b('item__title')}>{t('faq.data.11.title')}</div>
          <div>{t('faq.data.11.data')}</div>
        </div>
        <div className={b('item')}>
          <div className={b('item__title')}>{t('faq.data.12.title')}</div>
          <div>{t('faq.data.12.data.0')}</div>
          <div>
            <Trans
              i18nKey="faq.data.12.data.1"
              values={{ email: getEmailToDisplay }}
              components={[
                <Button type="link" className={b('link')}>
                  <a className={b('a')} href={`mailto:${getEmailToDisplay}`} />
                </Button>,
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(FAQ);
