export function getCurrentStep(state) {
  return state.main.step;
}

export function getGameInformation(state) {
  return {
    game: state.main.game,
    error: state.main.error,
    eod: state.main.eod,
  };
}

export function getUserInformation(state) {
  return {
    user: state.main.user,
    error: state.main.error,
  };
}

export function getForCountryStep(state) {
  return {
    location: state.main.location,
    userAgreed: state.main.userAgreed,
    country: state.main.country,
    userName: state.main.user.payload.name,
    error: state.main.error,
  };
}

export function getFriendRequestData(state) {
  // console.log("state.main", state.main);
  return {
    phase: state.main.phase,
    bot: state.main.bot,
    friendRequest: state.main.friendRequest,
    error: state.main.error,
  };
}

export function getTransferStatus(state) {
  return {
    phase: state.main.phase,
    transferStatus: state.main.transferStatus,
    error: state.main.error,
    userId: getUserId(state),
  };
}

export function getPhase(state) {
  return {
    phase: state.main.phase,
    error: state.main.error,
  };
}

export function getStepControl(state) {
  return {
    transferStatus: state.main.transferStatus,
    bonus: state.main.bonusCode,
    step: state.main.step,
  };
}

export function getIsLoading(state) {
  return state.main.isLoading;
}

export function getGiftCode(state) {
  return state.main.giftcode;
}

export function getUserId(state) {
  return state.main.user.payload.id;
}
