import React from 'react';
import PropTypes from 'prop-types';
import b_ from 'b_';
import './styles.scss';
import cx from '../../Utils/cx';

const b = b_.lock('ImageView');

function ImageView({ className, image }) {
  return (
    <div className={cx(b(), className)}>
      <div className={b('background')} />
      <img className={b('image')} src={image} alt="" />
    </div>
  );
}

ImageView.defaultProps = {
  className: '',
  image: '/static/image.png',
};

ImageView.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
};

export default ImageView;
