import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import b_ from 'b_';
import Title from 'Components/Title';
import Paragraph from 'Components/Paragraph';
import Input from 'Components/Input';
import Button from 'Components/Button';
import Card from 'Components/Card';
import SteamAvatar from 'Components/SteamAvatar';
import StepButton from 'Components/StepButton';
import LoaderOverlay from 'Components/LoaderOverlay';
import cx from 'Utils/cx';
import './styles.scss';
import { getUserInformation } from 'Reducers/main/selectors';
import { activateAccount } from '../../../Reducers/main/actions';
import ChangeAccount from 'Components/ChangeAccount';
import NextStep from 'Components/NextStep';
import SignInButton from 'Components/SignInButton';
import Alert from '../../../Components/Alert';
import { useTranslation } from 'react-i18next';

const b = b_.lock('SingInSteam');

function SingInSteam({ className = '', disabled }) {
  const { t } = useTranslation();
  const { user, error } = useSelector(getUserInformation, shallowEqual);
  const { payload, isLoading } = user;
  const [url, setUrl] = useState({ value: '', valid: true });
  const dispatch = useDispatch();

  const handleChange = useCallback(function(e) {
    setUrl({ value: e.target.value.trim(), valid: true });
  }, []);

  const checkAccountUrl = useCallback(() => {
    const steamId = url.value.match(
      /(^https?:\/\/steamcommunity\.com\/profiles\/)(\d{15,20})(\/|$)(.*)/
    );
    const nickname = url.value.match(
      /(^https?:\/\/steamcommunity\.com\/id\/)([a-zA-Z0-9\-_]+)(\/|$)(.*)/
    );
    if (
      (!steamId && !nickname) ||
      (steamId && steamId[4].length) ||
      (nickname && nickname[4].length)
    ) {
      setUrl({ value: url.value, valid: false });
      return;
    }

    dispatch(
      activateAccount(
        (steamId && `${steamId[1]}${steamId[2]}`) ||
          (nickname && `${nickname[1]}${nickname[2]}`)
      )
    );
  }, [dispatch, url]);

  return (
    <div className={cx(b(), className)}>
      <StepButton
        isComplete={!!payload}
        completeMessage={t('main.steps.one.step_button_message')}
      >
        1
      </StepButton>
      <div className={b('content', { disabled: disabled })}>
        <Title className={b('title')} level={2}>
          {t('main.steps.one.sign_in_steam')}
        </Title>
        {error && !disabled && <Alert className={b('error')} error={error} />}
        {isLoading && <LoaderOverlay />}
        {payload ? (
          <>
            <Card className={b('successCard')}>
              <SteamAvatar className={b('avatar')} avatar={payload.avatar} />
              <span className="bold">{payload.name} &nbsp;</span>
              <span> {t('main.steps.one.will_get_link')}</span>
              <div className={b('giftIcon')} />
            </Card>
            <Paragraph className={b('changeText')}>
              {t('main.steps.one.change_account_description')}
            </Paragraph>
            <div className="GroupButton">
              <NextStep className={b('nextStep')} />
              <ChangeAccount />
            </div>
          </>
        ) : (
          <>
            <Paragraph className={b('text')}>
              {t('main.steps.one.account_link_description')}
            </Paragraph>
            <div className={b('inputRow')}>
              <Input
                {...url}
                className={b('rowInput')}
                placeholder={t(
                  'controls.input_placeholder.insert_steam_profile_url'
                )}
                onChange={handleChange}
              />
              <Button
                className={b('rowButton')}
                type="primary"
                onClick={checkAccountUrl}
              >
                {t('controls.buttons.send_to_profile')}
              </Button>
            </div>
            <Paragraph className={b('example')}>
              <span className="bold">{t('main.steps.one.example')} </span>
              http://steamcommunity.com/profiles/76561111111111111
            </Paragraph>
            <Paragraph className={b('text')}>
              {t('main.steps.one.or_sign_in_with')}
            </Paragraph>
            <SignInButton className={b('singInButton')} />
            <NextStep className={b('nextStep')} disabled={true} />
          </>
        )}
      </div>
    </div>
  );
}

SingInSteam.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

export default SingInSteam;
