import React from 'react';
import './styles.scss';

/** Displays the version number of the application from .env at the top right of the page */
function PrintVersion() {
  return (
    <div className="PrintVersion">Version: {process.env.REACT_APP_VERSION}</div>
  );
}

export default PrintVersion;
