import React from 'react';
import PropTypes from 'prop-types';
import b_ from 'b_';
import './styles.scss';
import cx from '../../Utils/cx';

const b = b_.lock('Paragraph');

function Paragraph({ children, className = '' }) {
  return <div className={cx(b(), className)}>{children}</div>;
}
Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Paragraph;
