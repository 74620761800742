import React, { useEffect, useMemo, useRef, useState } from 'react';
import b_ from 'b_';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useBoolean from '../../Utils/useBoolean';
import useOnClickOutside from '../../Utils/useOnClickOutside';
import Input from '../Input';
import './styles.scss';
import Paragraph from '../Paragraph';
import cx from '../../Utils/cx';

const b = b_.lock('SearchSelect');

function SearchSelect({ value, onChange, options, placeholder, className }) {
  const element = useRef(null);
  const { t } = useTranslation();

  const {
    value: isOpen,
    toggleValue: toggleOpen,
    setFalse: close,
  } = useBoolean(false);
  const [inputValue, changeInputValue] = useState('');

  const showedValue = useMemo(() => {
    const finded = options.find(
      (option) => option.code === (value && value.code)
    );
    changeInputValue('');
    return (finded && finded.name) || '';
  }, [options, value]);

  useEffect(() => {
    close();
    changeInputValue('');
  }, [value, close]);

  const searched = useMemo(() => {
    const oldValue = inputValue;
    // eslint-disable-next-line
    const value = oldValue.replace(/[\\\[\]\^\$\'\`\"\d]/gi, '', -1).trim();
    if (oldValue !== value) changeInputValue(value);

    if (!value) {
      return options;
    }

    const reg = new RegExp((value.length > 1 ? '' : '^') + value, 'ig');

    return options.filter(({ name }) => name.match(reg));
  }, [options, inputValue]);

  useOnClickOutside(element, close);

  useEffect(() => {
    if (!isOpen) changeInputValue('');
  }, [isOpen]);

  return (
    <div ref={element} className={cx(b({ open: isOpen }), className)}>
      <div
        className={b('select', { open: isOpen, placeholder: !showedValue })}
        onClick={toggleOpen}
      >
        {showedValue || placeholder}
      </div>
      {isOpen && (
        <div className={b('dropdown')}>
          <Input
            type="search"
            value={inputValue}
            onChange={(e) => changeInputValue(e.target.value)}
            className={b('input')}
            placeholder={t('controls.input_placeholder.search')}
            autoFocus
          />
          <div className={b('options')}>
            {searched.length ? (
              searched.map((value) => (
                <div
                  className={b('option')}
                  key={value.code}
                  onClick={() => onChange({ ...value })}
                >
                  {value.name}
                </div>
              ))
            ) : (
              <Paragraph className={b('notFound')}>
                {t('controls.not_found')}
              </Paragraph>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

SearchSelect.defaultProps = {
  placeholder: '',
  className: '',
};

SearchSelect.propTypes = {
  value: PropTypes.shape({ code: PropTypes.string, name: PropTypes.string }),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default SearchSelect;
